<template>
  <div id="ProductShow">
    <div class="row" v-if="product != null">
      <div class="ProductImageBox">
        <div class="big_img_box">
          <img :src="GetFullPath(product.Image[active_image])" />
        </div>
        <div class="small_img_box">
          <div
            class="img_box"
            v-for="(item, item_index) in product.Image"
            @click="active_image = item_index"
            :key="item"
          >
            <img :src="GetFullPath(item)" />
          </div>
        </div>
      </div>
      <div class="ProductInfoBox">
        <h2 class="title sub_title_font">{{ product.Title }}</h2>
        <p class="category content_font">{{ series }}</p>
        <ol>
          <li class="content_font" v-for="item in product.Info" :key="item">
            {{ item }}
          </li>
        </ol>
        <div class="share_group">
          <p>
            <span class="material-icons-outlined sub_content_font"> share </span
            >SHARE
          </p>
          <button class="line_btn" @click="ShareToLine">
            <img src="@/assets/line.png" />
          </button>
          <button class="facebook_btn" @click="ShareToFB">
            <img src="@/assets/facebook.png" />
          </button>
        </div>
        <div class="btn_group">
          <button class="contact_btn content_font" @click="OpenLine()">
            <span class="material-icons-outlined"> chat_bubble </span>Contact Us
          </button>
          <button
            class="favorite_btn content_font"
            @click="AddToShopCart($route.params.id), ToggleGA()"
          >
            <span class="material-icons-outlined"> favorite_border </span>Add to
            Order
          </button>
        </div>
      </div>
      <div class="ProductContentBox" v-html="product.Content"></div>
    </div>
  </div>
</template>

<script>
import { GetMetaData } from '@/common/meta';
import { ReturnErrorPage } from '@/common/prerender_event';
export default {
  name: 'ProductShow',
  data() {
    return {
      active_image: 0,
      descriptionImages: [],
      descriptionImage: '',
      descriptionContent: '',
      descriptionTitle: '',
      meta_data: null,
    };
  },
  methods: {
    ShareToFB() {
      window
        .open(
          `https://www.facebook.com/sharer.php?u=${window.location.href}`,
          '_blank'
        )
        .focus();
    },
    ShareToLine() {
      window.open(`line://msg/text/${window.location.href}`, '_blank').focus();
    },
    OpenLine() {
      window.open('https://m.me/bobalegend');
    },
    GetProduct() {
      let product = this.$store.state.products.filter(
        (item) => item.ProductID == this.$route.params.id
      );
      product.length <= 0 ? (this.product = null) : (this.product = product);
    },
    async LoadData() {
      this.$store.commit('SetLoading', true);
      let response = await this.GetData('/product/' + this.$route.params.id);
      this.$store.commit('SetLoading', false);
      if (response != 'error') {
        if (response.length > 0) {
          this.GA_ViewItem(response[0]);
          this.descriptionTitle = response[0].Title;
          this.descriptionContent = response[0].Content.replace(
            /<[^>]+>/g,
            ''
          ).substring(0, 150);
          this.descriptionImage = this.GetFullPath(response[0].Image[0]);
          this.descriptionImages = response[0].Image;
          this.descriptionImages.forEach((item, item_index) => {
            this.descriptionImages[item_index] = this.GetFullPath(item);
          });

          this.meta_data = GetMetaData(
            this.descriptionTitle,
            this.descriptionContent,
            this.descriptionImage
          );
        } else {
          ReturnErrorPage();
        }
      }
    },
    ToggleGA() {
      this.GA_AddToCart(this.product);
    },
  },
  computed: {
    product() {
      let product = this.$store.state.products.filter(
        (item) => item.ProductID == this.$route.params.id
      );
      return product.length <= 0 ? null : product[0];
    },
    series() {
      if (this.product != null) {
        let series = this.$store.state.series.filter(
          (item) => item.ProductDirID == this.product.ProductDirID
        );
        return series.length <= 0 ? '' : series[0].Title;
      } else {
        return '';
      }
    },
    category() {
      if (this.product != null) {
        let category = this.$store.state.category.filter(
          (item) => item.ProductDir2ID == this.product.ProductDir2ID
        );
        return category.length <= 0 ? '' : category[0].Title;
      } else {
        return '';
      }
    },
  },
  created() {
    this.LoadData();
  },
  metaInfo() {
    return this.meta_data;
  },
  jsonld() {
    return {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: this.descriptionTitle,
      image: this.descriptionImages,
      description: this.descriptionContent,
      sku: ' SHENG_JIA_' + this.$route.params.id,
      brand: {
        '@type': 'Brand',
        name: 'ECO SHENG JIA TECHNOLOGY',
      },
      review: {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: '4',
          bestRating: '5',
        },
        author: {
          '@type': 'Person',
          name: '林泳欣',
        },
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.4',
        reviewCount: '89',
      },
    };
  },
};
</script>
